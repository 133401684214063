














import { defineComponent, computed } from '@vue/composition-api';

interface Props {
    loading: boolean;

    enabled: boolean;
}

/**
 * Button with a spinner
 */
export default defineComponent<Props>({
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        enabled: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props, { emit }) {
        const isLoading = computed(() => props.loading);

        const isEnabled = computed(() => props.enabled);

        function maybeClick() {
            if (isEnabled.value && !isLoading.value) {
                emit('click');
            }
        }

        return {
            isLoading,
            isEnabled,
            maybeClick,
        };
    },
});
