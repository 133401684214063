






















import { defineComponent, computed } from '@vue/composition-api';

/**
 * Select of the customer ids available to the current user
 */
export default defineComponent({
    setup(props, { root }) {
        if (root.$store) {
        const customerIds = computed(() => root.$store.getters.customerIds);

        const activeCustomerId = computed(() => root.$store.getters.activeCustomerId);

        function onSelect(event: any) {
            root.$store.dispatch('updateActiveCustomerId', event.target.value);
        }

        return {
            customerIds,
            activeCustomerId,
            onSelect,
        };
        }
    },
});
