




import { defineComponent, computed } from '@vue/composition-api';

interface RequiredIndicatorProps {
    text: string;
}

export default defineComponent<RequiredIndicatorProps>({
    props: {
        text: {
            type: String,
            default: 'Pflichtfeld',
        },
    },
});
