import { apiBaseUrl } from '@/services/api';
import Document from '@/types/Document';
import { compareTaskSites } from './location';
import { parseISO } from 'date-fns';

/**
 * Convert some raw json props
 */
export function materializeDocuments(data: any): Document[] {
    return data.map((i: any) => {
        return {
            ...i,
            postingDate: parseISO(i.postingDate),
            documentDate: parseISO(i.documentDate),
        } as Document;
    });
}

/**
 * Build document pdf download url
 */
export function buildDownloadUrl(doc: Document) {
    return `${apiBaseUrl}downloads?documentRef=${doc.ref}`;
}

/**
 * Compare by date, descending. For sorting
 */
export function compareDocsByDate(a: Document, b: Document) {
    if (a.postingDate < b.postingDate) {
        return 1;
    }

    if (a.postingDate > b.postingDate) {
        return -1;
    }

    return 0;
}

/**
 * Compare by location, ascending. For sorting
 */
export function compareDocsByLocation(a: Document, b: Document) {
    if (a.taskSite && b.taskSite) {
        const locationSort = compareTaskSites(a.taskSite, b.taskSite);

        if (locationSort !== 0) {
            return locationSort;
        }
    }

    return compareDocsByDate(a, b);
}
