




































import Datepicker from 'vuejs-datepicker';
import { defineComponent, computed, ref } from '@vue/composition-api';
import { defaultDatepickerConfig, defaultFilterDates } from '@/services/dates';

/**
 * Date range filter
 */
export default defineComponent({
    components: {
        Datepicker,
    },
    setup(props, { emit }) {
        const from = ref<Date>(defaultFilterDates.from);

        const to = ref<Date>(defaultFilterDates.to);

        const fromConfig = computed(() => {
            return {
                ...defaultDatepickerConfig,
                disabledDates: {
                    from: to.value,
                },
            };
        });

        const toConfig = computed(() => {
            return {
                ...defaultDatepickerConfig,
                disabledDates: {
                    to: from.value,
                },
            };
        });

        function fromChanged(newDate: Date | null) {
            if (!newDate) {
                return;
            }

            emit('selected', {
                from: newDate,
                to: to.value,
            });
        }

        function toChanged(newDate: Date | null) {
            if (!newDate) {
                return;
            }

            emit('selected', {
                from: from.value,
                to: newDate,
            });
        }

        return {
            from,
            to,
            fromConfig,
            toConfig,
            fromChanged,
            toChanged,
        };
    },
});
