import { ActionTree, Commit } from 'vuex';

import RootState from './state';
import Api from '@/services/api';
import FilterDates from '@/types/FilterDates';
import UpdateUserModel from '@/types/UpdateUserModel';
import AddCustomerUserModel from '@/types/AddCustomerUserModel';
import User from '@/types/User';
import CreateTaskSiteModel from '@/types/CreateTaskSiteModel';
import CreateContractModel from '@/types/CreateContractModel';
import CreateOrderModel from '@/types/CreateOrderModel';
import Contract from '@/types/Contract';
import Order from '@/types/Order';
import { materializeDocuments } from '@/services/documents';
import TaskSite from '@/types/TaskSite';
import Category from '@/types/Category';
import ContractTemplate from '@/types/ContractTemplate';
import Equipment from '@/types/Equipment';
import Customer from '@/types/Customer';
import { materializeOrders } from '@/services/orders';
import Holiday from '@/types/Holiday';
import { materializeHolidays } from '@/services/dates';
import { materializeContracts } from '@/services/contracts';

const api = new Api();

/**
 * Update state from any response
 *
 * Mainly used to register 401 responses which indicate that the cookie expired
 */
function updateStateFromResponse(r: Response | undefined, commit: Commit) {
    if (!r) {
        return;
    }

    if (r.status === 401) {
        commit('setLoggedIn', false);
    }
}

/**
 * Simple check if a request failed
 */
function failed(r?: Response) {
    if (!r || !r.ok) {
        return true;
    }

    return false;
}

/**
 * Actions for the store
 */
export const actions: ActionTree<RootState, RootState> = {
    async fetchAll({ dispatch, commit }) {
        commit('setLoading', true);

        await dispatch('fetchOrders');

        commit('setLoading', false);
    },

    async fetchCustomer({ commit, getters }) {
        const r = await api.fetchCustomer(getters.activeCustomerId);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return null;
        }

        const customer = (await r.json()) as Customer;

        commit('setCustomer', customer);

        return customer;
    },

    /**
     * Fetches and sets orders for a customer
     */
    async fetchOrders({ commit, getters }) {
        const r = await api.fetchOrders(getters.activeCustomerId);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return [];
        }

        const orders = materializeOrders(await r.json());

        commit('setOrders', orders);

        return orders;
    },

    /**
     * Deletes an order
     */
    async deleteOrder(
        { getters, commit },
        { taskSiteId, orderId }: { taskSiteId: string; orderId: string }
    ) {
        const r = await api.deleteOrder(getters.activeCustomerId, taskSiteId, orderId);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return { ok: false };
        }

        return {
            ok: r.ok,
        };
    },

    /**
     * Fetches contracts
     */
    async fetchContracts({ getters, commit }) {
        const r = await api.fetchContracts(getters.activeCustomerId);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return [];
        }

        const contracts = materializeContracts(await r.json());

        commit('setContracts', contracts);

        return contracts;
    },

    /**
     * Fetches contracts for a task site
     */
    async fetchTaskSiteContracts({ getters, commit }, taskSiteId: string) {
        const r = await api.fetchTaskSiteContracts(getters.activeCustomerId, taskSiteId);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return [];
        }

        const contracts = materializeContracts(await r.json());

        return contracts;
    },

    async updateActiveCustomerId({ commit }, id: string) {
        const r = await api.updateActiveCustomerId(id);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return;
        }

        commit('setActiveCustomerId', id);
        commit('clearCustomerData');
    },

    async fetchCurrentUser({ commit }) {
        commit('setUserLoading', true);

        const r = await api.fetchCurrentUser();

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            commit('setUserLoading', false);
            return null;
        }

        const user = (await r.json()) as User;

        commit('setUser', user);
        commit('setUserLoading', false);

        return user;
    },

    async fetchDocuments({ commit, getters }, dates: FilterDates) {
        // TODO: Refactor this into a `loadForDocuments` method and move the
        // state mutating (setLoading) there to keep this simple
        commit('setLoading', true);

        const r = await api.fetchDocuments(getters.activeCustomerId, dates);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            commit('setLoading', false);
            return [];
        }

        const documents = materializeDocuments(await r.json());

        commit('setDocuments', documents);

        commit('setLoading', false);

        return documents;
    },

    /**
     * Fetch documents for a specific task site
     */
    async fetchTaskSiteDocuments(
        { commit, getters },
        { taskSiteId, dates }: { taskSiteId: string; dates: FilterDates }
    ) {
        const r = await api.fetchTaskSiteDocuments(getters.activeCustomerId, taskSiteId, dates);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return [];
        }

        const documents = materializeDocuments(await r.json());

        commit('setTaskSiteDocuments', { taskSiteId, documents });

        return documents;
    },

    async fetchTaskSites({ commit, getters }) {
        const r = await api.fetchTaskSites(getters.activeCustomerId);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return [];
        }

        const taskSites: TaskSite[] = await r.json();

        commit('setTaskSites', taskSites);

        return taskSites;
    },

    async fetchCategories({ commit }) {
        const r = await api.fetchCategories();

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return [];
        }

        const categories: Category[] = await r.json();

        commit('setCategories', categories);

        return categories;
    },

    async fetchContractTemplates({ commit }) {
        const r = await api.fetchContractTemplates();

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return [];
        }

        const templates: ContractTemplate[] = await r.json();

        commit('setContractTemplates', templates);

        return templates;
    },

    async fetchEquipment({ commit }) {
        const r = await api.fetchEquipment();

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return [];
        }

        const equipment: Equipment[] = await r.json();

        equipment.sort((a, b) => a.description.localeCompare(b.description));

        commit('setEquipment', equipment);

        return equipment;
    },

    async createTaskSite({ getters, commit }, model: CreateTaskSiteModel) {
        const r = await api.createTaskSite(getters.activeCustomerId, model);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return {
                ok: false,
                taskSite: null,
            };
        }

        const taskSite: TaskSite = await r.json();

        return {
            ok: true,
            taskSite,
        };
    },

    async createContract(
        { getters, commit },
        payload: { taskSiteId: string; model: CreateContractModel }
    ): Promise<{ ok: boolean; contract: Contract | null }> {
        const r = await api.createContract(
            getters.activeCustomerId,
            payload.taskSiteId,
            payload.model
        );

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return {
                ok: false,
                contract: null,
            };
        }

        const contract: Contract = await r.json();

        return {
            ok: true,
            contract,
        };
    },

    async createOrder(
        { getters, commit },
        payload: { taskSiteId: string; model: CreateOrderModel }
    ): Promise<{ ok: boolean; order: Order | null }> {
        const r = await api.createOrder(
            getters.activeCustomerId,
            payload.taskSiteId,
            payload.model
        );

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return {
                ok: false,
                order: null,
            };
        }

        const order: Order = await r.json();

        return {
            ok: true,
            order,
        };
    },

    async sendOrderMessage(
        { getters, commit },
        payload: { message: string; replyTo: string; orderId: string; contractId: string }
    ) {
        const r = await api.sendOrderMessage(
            getters.activeCustomerId,
            payload.contractId,
            payload.orderId,
            payload.message,
            payload.replyTo
        );

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return false;
        }

        return r.ok;
    },

    async fetchUsers({ commit, getters }) {
        const r = await api.fetchUsers(getters.activeCustomerId);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return [];
        }

        const users: User[] = await r.json();

        commit('setUsers', users);

        return users;
    },

    async updateUser({ commit, getters }, model: UpdateUserModel) {
        const r = await api.updateUser(getters.activeCustomerId, model);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return null;
        }

        const updatedUser: User = await r.json();

        const users: User[] = getters.users;

        const existingIndex = users.findIndex((u) => u.id === updatedUser.id);

        users[existingIndex] = updatedUser;

        commit('setUsers', users);

        return updatedUser;
    },

    async addCustomerUser({ getters, commit }, model: AddCustomerUserModel) {
        const r = await api.addCustomerUser(getters.activeCustomerId, model, false);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return {
                ok: false,
                added: false,
                invited: false,
            };
        }

        return {
            ok: r.ok,
            added: r.status === 204,
            invited: r.status === 202,
        };
    },

    async removeCustomerUser({ getters, commit }, email: string) {
        const r = await api.removeCustomerUser(getters.activeCustomerId, email);

        updateStateFromResponse(r, commit);

        if (failed(r)) {
            return false;
        }

        return r.ok;
    },

    async fetchHolidays({ commit }) {
        const r = await api.fetchHolidays();

        if (failed(r)) {
            return [];
        }

        const holidays: Holiday[] = materializeHolidays(await r.json());

        commit('setHolidays', holidays);

        return holidays;
    },

    async loadForNewOrder({ commit, dispatch }) {
        commit('setLoading', true);

        await Promise.all([dispatch('fetchTaskSites')]);

        commit('setLoading', false);
    },

    async loadForUserManagement({ commit, dispatch }) {
        commit('setLoading', true);

        await Promise.all([dispatch('fetchUsers')]);

        commit('setLoading', false);
    },

    async loadForOverview({ commit, dispatch }) {
        commit('setLoading', true);

        await Promise.all([dispatch('fetchOrders'), dispatch('fetchContracts')]);

        commit('setLoading', false);
    },
};
