import { GetterTree } from 'vuex';
import RootState from './state';
import { ClaimTypes } from '@/services/user';
import Category from '@/types/Category';
import Equipment, { EquipmentType } from '@/types/Equipment';
import ContractTemplate from '@/types/ContractTemplate';
import Order from '@/types/Order';
import { isSameDay } from 'date-fns';

export const getters: GetterTree<RootState, RootState> = {
    customerIds: (state) => state.customerIds,

    activeCustomerId: (state) => state.activeCustomerId,

    /**
     * Combines active orders with the same contract id (same equipment)
     * into one and sums quantities
     */
    orders: (state) => {
        const orders = [];

        const orderMap: { [key: string]: Order } = {};

        for (const order of state.orders) {
            // Not active, these should not be combined
            if (order.status.code !== 'ACTIVE') {
                orders.push(order);

                continue;
            }

            // Use contract and equipment code as key
            const key = `${order.contractId}-${order.equipmentCode}`;

            if (orderMap[key]) {
                const masterOrder = orderMap[key];

                masterOrder.quantity += order.quantity;

                if (!masterOrder.orderRef && order.orderRef) {
                    masterOrder.orderRef = order.orderRef;
                }
            } else {
                orderMap[key] = order;

                orders.push(order);
            }
        }

        return orders;
    },

    contracts: (state) => state.contracts,

    /**
     * Check if the tasksite of the given order has a retrieval order currently
     * active
     */
    hasRetrievalOrder: (state) => (order: Order) =>
        state.orders.some(
            (o: Order) =>
                o.equipmentType === EquipmentType.PICKUP &&
                o.taskSiteCode === order.taskSiteCode &&
                o.status.code !== 'FINISHED'
        ),

    taskSites: (state) => state.taskSites,

    equipment: (state) => state.equipment,

    equipmentTitle: (state) => (code: string) => {
        const equipment = state.equipment.find((e: Equipment) => e.code === code);

        if (!equipment) {
            return code;
        }

        return `${equipment.description}`;
    },

    /**
     * Get all equipment that the given contract templates are associated with
     */
    availableEquipmentForContracts: (state) => (contracts: ContractTemplate[]) => {
        const equipmentIds = contracts.map((c) => c.equipmentCode);

        return state.equipment.filter((e) => equipmentIds.includes(e.code));
    },

    categories: (state) => state.categories,

    contractTemplates: (state) => state.contractTemplates,

    /**
     * Get contracts that match the given category and equipment
     */
    filteredContractTemplates: (state) => (c: Category, e: Equipment) =>
        state.contractTemplates.filter(
            (t) => t.categoryCode === c.code && t.equipmentCode === e.code
        ),

    documents: (state) => state.documents,

    taskSiteDocuments: (state) => (taskSiteId: string) => state.taskSiteDocuments[taskSiteId] || [],

    loading: (state) => state.loading,
    userLoading: (state) => state.userLoading,

    orderModel: (state) => state.newOrderModel,

    holidays: (state) => state.holidays,

    holidayOn: (state) => (date: Date) => state.holidays.find((h) => isSameDay(h.date, date)),

    user: (state) => state.user,

    customer: (state) => state.customer,

    loggedIn: (state) => state.loggedIn,

    users: (state) => state.users,

    /**
     * Check if user has order permission
     */
    canOrder: (state, { activeCustomerId }) => {
        if (!state.user) {
            return false;
        }

        return state.user.claims.some(
            (c) => c.type === ClaimTypes.PortalOrder && c.value === activeCustomerId
        );
    },

    /**
     * Check if user has documents permission
     */
    canSeeDocuments: (state, { activeCustomerId }) => {
        if (!state.user) {
            return false;
        }

        return state.user.claims.some(
            (c) => c.type === ClaimTypes.PortalDocuments && c.value === activeCustomerId
        );
    },

    /**
     * Check if user has user management permission
     */
    canManageUsers: (state, { activeCustomerId }) => {
        if (!state.user) {
            return false;
        }

        return state.user.claims.some(
            (c) => c.type === ClaimTypes.PortalUserManagement && c.value === activeCustomerId
        );
    },
};
