/**
 * An additional option that can be selected when booking this equipment
 */
export interface EquipmentOptionTemplate {
    code: string;

    description: string;

    amount: number;
}

export enum EquipmentType {
    DELIVERY = 0,
    PICKUP = 1,
}

/**
 * NAV equipment
 */
export default interface Equipment {
    code: string;

    description: string;

    description2: string;

    comment: string;

    iconRef: string;

    height: number;

    width: number;

    depth: number;

    volume: number;

    /**
     * Options that are available for this equipment
     *
     * Need to be cross-checked with the contract templates for this equipment
     */
    options: EquipmentOptionTemplate[];
}
