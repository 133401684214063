






























import { defineComponent } from '@vue/composition-api';
import { ConfirmDialogProps } from '@/services/dialogs';

export default defineComponent<ConfirmDialogProps>({
    props: {
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            required: true,
        },
        cancel: {
            type: String,
            default: 'Abbrechen',
        },
        ok: {
            type: String,
            default: 'OK',
        },
        destructive: {
            type: Boolean,
            default: false,
        },
    },
});
