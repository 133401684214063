import { parseISO } from 'date-fns';
import Contract from '@/types/Contract';

export function materializeContracts(data: any): Contract[] {
    return data.map((i: any) => {
        return {
            ...i,
            startingDate: i.startingDate ? parseISO(i.startingDate) : null,
        } as Contract;
    });
}
