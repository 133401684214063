









import { defineComponent, computed } from '@vue/composition-api';

import Info from '@/components/shared/Info.vue';

export default defineComponent({
    components: {
        Info,
    },
    props: {
        holiday: {
            type: Object,
            default: null,
        },
    },
    setup(props) {
        const staticMsg =
            'Bitte beachten Sie, dass bei gesetzlichen Feiertagen, je nach Region, der Auftrag frühestens am nächsten Werktag ausgeführt werden kann';
        const msg = computed(() =>
            props.holiday ? `Feiertag: ${props.holiday.title}<br>${staticMsg}` : staticMsg
        );

        return { msg };
    },
});
