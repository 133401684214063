










import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
    setup(props, { root }) {
        const loggedIn = computed(() => root.$store.getters.loggedIn);

        return {
            loggedIn,
        };
    },
});
